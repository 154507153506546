/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useWindowScrollPosition } from "rooks"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const { scrollY } = useWindowScrollPosition()

  return (
    <>
      <GatsbySeo
        title={`Elegares`}
        description={`We serve globally to modernise static sites to take advantage of the blazing speeds of Gatsby while eliminating WordPress monthly maintenance and even probably, server costs.`}
      />
      <div
        className={
          process.env.NODE_ENV === "development" ? "debug-screens" : ""
        }
      >
        <div className="flex flex-col h-screen">
          <Header scrollY={scrollY} />
          <main className="flex-auto">{children}</main>
          <Footer />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
