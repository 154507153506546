import React from "react"
import { Link } from "gatsby"

import Section from "./section"
import Container from "./container"
import CardText from "./card-text"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <>
      <footer>
        <Section className="!py-0 bg-white">
          <Container className="">
            <div className="flex flex-col items-center gap-6 py-10 laptop:py-6 laptop:gap-20 laptop:flex-row">
              <StaticImage
                src="../images/logo.svg"
                alt=""
                width={128}
                className="flex-shrink-0"
              ></StaticImage>
              <CardText className="text-center laptop:text-left text-neutral-500">
                Copyright © 2022 Elegares Private Limited, a registered company
                in Singapore with UEN 201818780E. All rights reserved.
              </CardText>
            </div>
          </Container>
        </Section>
      </footer>
    </>
  )
}

export default Footer
