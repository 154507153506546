import React from "react"

const Section = ({ children, className = "" }) => {
  return (
    <>
      <section
        className={`laptop:py-24 tablet:py-20 py-12 max-w-[1440px] mx-auto ${className}`}
      >
        {children}
      </section>
    </>
  )
}

export default Section
