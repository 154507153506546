import React from "react"

const Container = ({ children, className = "" }) => {
  return (
    <>
      <div className={`tablet:px-10 px-5 mx-auto max-w-[1216px] ${className}`}>
        {children}
      </div>
    </>
  )
}

export default Container
