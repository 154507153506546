import React from "react"

const CardText = ({ children, className = "", ...rest }) => {
  return (
    <>
      <div className={`text-neutral-700 text-body-md ${className}`}>
        {children}
      </div>
    </>
  )
}

export default CardText
