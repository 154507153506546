import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Container from "./container"
import CardText from "./card-text"

const Header = ({ scrollY }) => {
  const [activeMobileMenu, setActiveMobileMenu] = useState(false)

  const linkTo = link => {
    setActiveMobileMenu(false)
    navigate(link)
  }

  return (
    <>
      <div
        className={`fixed bg-black/70 left-1/2 top-0 w-full z-50 transition-all ease-in-out duration-500 max-w-[1440px] -translate-x-1/2	 ${
          scrollY !== 0 ? "!bg-black/70" : "!bg-transparent"
        }`}
      >
        <header
          className={`py-4 laptop:py-5  ${
            activeMobileMenu ? "bg-white laptop:bg-transparent" : ""
          }`}
        >
          <Container>
            <div className="flex items-center justify-between gap-8 ">
              <Link to="/" className="inline-block">
                <StaticImage src="../images/logo.svg" alt="logo" width={140} />
              </Link>
              <div className="hidden gap-6 pl-5 font-semibold text-center text-white uppercase laptop:flex text-body-md">
                <Link to="/#about" className="inline-block px-2 py-1">
                  About
                </Link>
                <Link to="/#portfolio" className="inline-block px-2 py-1">
                  Portfolio
                </Link>
                <Link to="/#pricing" className="inline-block px-2 py-1">
                  Pricing
                </Link>
                <Link
                  to="/#contact"
                  className="inline-block px-6 py-1 bg-primary-500"
                >
                  Contact
                </Link>
              </div>
              <div className="flex items-center laptop:hidden">
                {activeMobileMenu ? (
                  <Link
                    to="/#contact"
                    className="items-center hidden h-8 px-6 py-1 font-semibold border tablet:inline-flex laptop:hidden border-primary-500 text-primary-500"
                  >
                    Contact
                  </Link>
                ) : (
                  <Link
                    to="/#contact"
                    className="items-center hidden h-8 px-6 py-1 font-semibold text-white tablet:inline-flex laptop:hidden bg-primary-500"
                  >
                    Contact
                  </Link>
                )}

                <button
                  className="p-2 ml-8 laptop:hidden"
                  onClick={() => {
                    setActiveMobileMenu(!activeMobileMenu)
                  }}
                >
                  {activeMobileMenu ? (
                    <StaticImage src="../images/svg/close.svg" alt="" />
                  ) : (
                    <StaticImage src="../images/svg/menu.svg" alt="" />
                  )}
                </button>
              </div>
            </div>
          </Container>
        </header>
        {activeMobileMenu && (
          <div className="h-[1px] bg-neutral-100 laptop:hidden"></div>
        )}

        <div
          className={`relative  laptop:hidden ${
            activeMobileMenu ? "" : "hidden"
          }`}
        >
          <div className="!absolute inset-x-0 top-0 z-50 ">
            <div className="flex flex-col items-center gap-2 p-6 text-left bg-white shadow-lg">
              <Link
                to="/#about"
                onClick={() => linkTo("/#about")}
                className="inline-block p-3 "
              >
                <CardText>About</CardText>
              </Link>
              <Link
                to="/#portfolio"
                onClick={() => linkTo("/#portfolio")}
                className="inline-block p-3"
              >
                <CardText>Portfolio</CardText>
              </Link>
              <Link
                to="/#pricing"
                onClick={() => linkTo("/#pricing")}
                className="inline-block p-3 "
              >
                <CardText>Pricing</CardText>
              </Link>

              <div>
                <Link
                  to="/#contact"
                  onClick={() => linkTo("/#contact")}
                  className="inline-block px-6 py-1 bg-primary-500 !text-white tablet:hidden"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
